import { Environment } from './types';

export const environment: Environment = {
  domain: 'e2e',
  production: true,
  giantBranchSeq: 2622,
  apiUrl: 'https://api.wiseconvey.com',
  nodeUrl: 'https://node.dev-wiseconvey.com',
  sentryKey: 'https://02939072ca1eb344ac68473d8d306d68@o4507122675744768.ingest.us.sentry.io/4507122678366208',
};
